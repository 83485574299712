$beige: #FAF6EC;;
$orange: #FF4D00;
$green: #475849;
$white:#FFFFFF;

$body: "Epilogue", sans-serif;;
$headline: "Jost", sans-serif;
$accent-font: "Epilogue", sans-serif;

@mixin motion {
    @media (prefers-reduced-motion: no-preference) {
        @content;
    }
}

@keyframes fade-enter {
    to {
        opacity: 1;
        transform: none;
    }
}

@mixin fade-enter($delay) {
    @include motion {
        animation: fade-enter .5s ease-out forwards;
        animation-delay: $delay;
        opacity: 0;
        transform: translateY(1rem);
    }
}

@mixin md {
    @media screen and (min-width: 800px) {
        @content;
    }
}

@mixin lg {
    @media screen and (min-width: 1200px) {
        @content;
    }
}


%fill-parent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

* {
    box-sizing: border-box;
}

body,
html {
    color: $green;
    font-family: $body;
    font-size: 16px;
    line-height: 1.3333;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include md {
        font-size: 18px;
    }

    @include lg {
        font-size: 20px;
    }
}

body {
    overflow-x: hidden;
}

button,
a.btn {
    appearance: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    align-items: center;
    box-shadow: 4px 4px 10px rgba(#000, .1);
    border: 2px solid $green;
    background-color: $green;
    color: $beige;
    min-height: 46px;
    font-size: 1.25rem;
    font-family: $headline;
    padding: .25rem 1.5rem;
    background: $green;
    display: inline-flex;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .2em;
    transition-property: background-color, border-color, color;
    transition-duration: 120ms;
    transition-timing-function: ease;

    &:hover {
        color: $green;
        background-color: $beige;
    }
}

.container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 0 auto;

    @include md {
        flex-wrap: nowrap;
    }
}

footer {
    background: $green;
    color: $beige;
    position: relative;
    justify-content: center;
    padding: 40px 8px;
    text-align: center;

    div {
        width:100%;
        display: inline-block;
        margin: 20 auto;
        // position: absolute;
    }

    p {
        min-width:150px;
        width: 20%;
        font-size: 14px;
        border: 1px solid currentColor;
        padding: 1rem;
        margin: 0 auto;
    }

    .footer-logo{
        display:inline;
        height:.75rem;
        width:auto;
    }
}