@keyframes fade-enter {
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:nth-child(1) {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

* {
  box-sizing: border-box;
}

body, html {
  color: #475849;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Epilogue, sans-serif;
  font-size: 16px;
  line-height: 1.3333;
}

@media screen and (min-width: 800px) {
  body, html {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  body, html {
    font-size: 20px;
  }
}

body {
  overflow-x: hidden;
}

button, a.btn {
  appearance: none;
  cursor: pointer;
  color: #faf6ec;
  min-height: 46px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .2em;
  background: #475849;
  border: 2px solid #475849;
  border-radius: 0;
  align-items: center;
  padding: .25rem 1.5rem;
  font-family: Jost, sans-serif;
  font-size: 1.25rem;
  text-decoration: none;
  transition-property: background-color, border-color, color;
  transition-duration: .12s;
  transition-timing-function: ease;
  display: inline-flex;
  box-shadow: 4px 4px 10px #0000001a;
}

button:hover, a.btn:hover {
  color: #475849;
  background-color: #faf6ec;
}

.container {
  max-width: 1400px;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0 auto;
  display: flex;
}

@media screen and (min-width: 800px) {
  .container {
    flex-wrap: nowrap;
  }
}

footer {
  color: #faf6ec;
  text-align: center;
  background: #475849;
  justify-content: center;
  padding: 40px 8px;
  position: relative;
}

footer div {
  width: 100%;
  margin: 20px auto;
  display: inline-block;
}

footer p {
  min-width: 150px;
  width: 20%;
  border: 1px solid;
  margin: 0 auto;
  padding: 1rem;
  font-size: 14px;
}

footer .footer-logo {
  height: .75rem;
  width: auto;
  display: inline;
}

.bg {
  height: 25vh;
  width: 100%;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .bg {
    width: auto;
    height: auto;
    z-index: -1;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

.bg img {
  object-fit: cover;
  max-height: 100%;
  width: 100%;
}

body {
  background-color: #faf6ec;
}

.header {
  margin: .25rem 2rem;
}

@media screen and (min-width: 1200px) {
  .header {
    margin: 1rem 10rem;
  }
}

.header h1 {
  color: #ff4d00;
  margin: 0;
  font-family: Jost, sans-serif;
  font-size: 3rem;
  font-weight: 800;
}

@media screen and (min-width: 1200px) {
  .header h1 {
    font-size: 6rem;
  }
}

.header h2 {
  color: #ff4d00;
  margin: 0;
  font-family: Jost, sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
}

@media screen and (min-width: 1200px) {
  .header h2 {
    font-size: 4rem;
  }
}

.hidden {
  display: none;
}

.form {
  min-height: 100vh;
  background: #475849f2;
  padding: 1rem 20px 5rem;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .form {
    width: 1200px;
    margin: auto;
  }
}

.form img {
  height: auto;
  max-width: 100%;
  width: 500px;
  margin: 0 auto;
  display: block;
}

.form p {
  color: #faf6ec;
  text-align: left;
  margin: 0 0 1.5rem;
  font-family: Jost, sans-serif;
  font-size: 1.25rem;
  line-height: 1.1;
}

.form p.thank-you {
  font-family: Epilogue, sans-serif;
}

.form p strong {
  color: #ff4d00;
}

.form p strong.bad {
  color: currentColor;
  text-underline-offset: 5px;
  -webkit-text-decoration: underline #ff4d00;
  text-decoration: underline #ff4d00;
  text-decoration-thickness: 4px;
}

.form p em {
  text-transform: uppercase;
  font-family: Epilogue, sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
}

.form-wrap {
  position: relative;
}

.form-wrap[aria-busy="true"] .loading {
  display: flex;
}

.form-wrap .loading {
  opacity: 85;
  align-items: left;
  width: 100%;
  height: 100%;
  background-color: #475849cc;
  justify-content: left;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px 8px #475849cc;
}

b {
  color: #ff4d00;
}

i {
  font-size: 1rem;
}

form {
  color: #faf6ec;
  width: 100%;
  max-width: 550px;
  margin: 0;
}

form > div {
  width: 100%;
  margin-bottom: .75rem;
  position: relative;
}

form > div.half {
  width: 50%;
}

form label {
  font-family: Jost, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  display: block;
}

form input:not([type="checkbox"]) {
  appearance: none;
  height: 40px;
  color: #faf6ec;
  width: 100%;
  background-color: #0000;
  border: 2px solid #faf6ec;
  padding: 0 .5rem;
  font-family: Epilogue, sans-serif;
  font-size: 1rem;
  display: block;
}

form input:not([type="checkbox"]):focus {
  background-color: #faf6ec1a;
}

form textarea {
  appearance: none;
  height: 40px;
  color: #faf6ec;
  width: 100%;
  height: 10rem;
  background-color: #0000;
  border: 2px solid #faf6ec;
  padding: .5rem;
  font-family: Epilogue, sans-serif;
  font-size: 1rem;
  display: block;
}

form textarea:focus {
  background-color: #faf6ec1a;
}

form select {
  height: 40px;
  color: #faf6ec;
  width: 100%;
  background-color: #0000;
  border: 2px solid #faf6ec;
  padding: 0 .5rem;
  font-size: 1rem;
  display: block;
}

form option {
  color: #475849cc;
  background-color: #faf6ec1a;
}

form fieldset {
  appearance: none;
  border: 0;
  padding: 0;
}

form fieldset legend {
  color: #faf6ec;
  font-family: Jost, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  display: block;
}

form fieldset > div {
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

form fieldset > div label {
  cursor: pointer;
  margin-left: .4rem;
  font-family: Epilogue, sans-serif;
  font-size: .9rem;
  font-weight: normal;
}

form .required:after {
  content: " *";
  color: #ff4d00;
}

form button {
  width: 100%;
  background-color: #ff4d00;
  border: 1px solid #faf6ec;
  justify-content: center;
}

footer {
  border-top: 2px solid #faf6ec;
  padding: 1rem 0;
}

/*# sourceMappingURL=index.d44b21e8.css.map */
