@import './site.scss';

.bg {
    position: relative;
    height: 25vh;
    width: 100%;

    @include lg {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: auto;
        height: auto;
        display: flex;
        z-index: -1;
    }

    img {
        object-fit: cover;
        max-height: 100%;
        width: 100%;
    }
}

body{
    background-color: $beige;
}

.header{
    margin: .25rem 2rem;

    @include lg {
        margin:1rem 10rem;
    }

    h1{
        font-family:$headline;
        color:$orange;
        font-size: 3rem;
        font-weight:800;
        margin: 0px;

        @include lg {
            font-size:6rem;
        }
    }
    h2{
        font-family:$headline;
        color:$orange;
        font-size: 1.5rem;
        font-weight:800;
        margin:0px;

        @include lg {
            font-size:4rem;
        }
    }
}

.hidden {
    display: none;
}

.form {
    background: rgba($green, .95);
    min-height: 100vh;
    padding: 1rem 20px 5rem;
    position: relative;

    @include lg {
        margin:auto;
        width: 1200px;
    }

    img {
        height: auto;
        max-width: 100%;
        width: 500px;
        margin: 0 auto;
        display: block;
    }

    p {
        color: $beige;
        font-family: $headline;
        font-size: 1.25rem;
        line-height: 1.1;
        text-align: left;
        margin: 0 0 1.5rem;

        &.thank-you {
            font-family: $body;
        }

        strong {
            color: $orange;

            &.bad {
                color: currentColor;
                text-decoration: underline;
                text-underline-offset: 5px;
                text-decoration-thickness: 4px;
                text-decoration-color: $orange;
            }
        }

        em {
            font-family: $accent-font;
            font-style: normal;
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}

.form-wrap {
    position: relative;

    &[aria-busy="true"] .loading {
        display: flex;
    }

    .loading {
        background-color: rgba($green, .8);
        box-shadow: 0 0 8px 8px rgba($green, .8);
        display: none;
        opacity: 85;
        align-items: left;
        justify-content: left;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

b{
    color:$orange;
}

i{
    font-size:1rem;
}

form {
    color: $beige;
    width: 100%;
    max-width: 550px;
    margin: 0;

    > div {
        position: relative;
        margin-bottom: .75rem;
        width: 100%;

        &.half {
            width: 50%;
        }
    }

    label {
        display: block;
        font-family: $headline;
        font-weight: 700;
        font-size: 1.25rem;
    }

    input:not([type="checkbox"]) {
        appearance: none;
        border: 2px solid $beige;
        background-color: transparent;
        height: 40px;
        display: block;
        padding: 0 .5rem;
        font-size: 1rem;
        color: $beige;
        width: 100%;
        font-family: $body;

        &:focus {
            background-color: rgba($beige, .1);
        }
    }

    textarea {
        appearance: none;
        border: 2px solid $beige;
        background-color: transparent;
        height: 40px;
        display: block;
        padding: .5rem .5rem;
        font-size: 1rem;
        color: $beige;
        width: 100%;
        height:10rem;
        font-family: $body;

        &:focus {
            background-color: rgba($beige, .1);
        }
    }

    select {
        border: 2px solid $beige;
        background-color: transparent;
        height: 40px;
        display: block;
        padding: 0 .5rem;
        font-size: 1rem;
        color: $beige;
        width: 100%;

    }

    option {
        background-color: rgba($beige, .1);
        color: rgba($green, .8)
    }

    fieldset {
        appearance: none;
        padding: 0;
        border: 0;

        legend {
            display: block;
            font-family: $headline;
            font-size: 1.25rem;
            color: $beige;
            font-weight: 700;
        }

        > div {
            align-items: center;
            display: flex;
            margin-bottom: .5rem;

            label {
                cursor: pointer;
                font-family: $body;
                font-size: .9rem;
                font-weight: normal;
                margin-left: .4rem;
            }

        }
    }

    .required:after {
        content:" *";
        color: $orange;
      }

    button {
        border: 1px solid $beige;
        background-color: $orange;
        width: 100%;
        justify-content: center;
    }
}

footer {
    border-top: 2px solid $beige;
    padding: 1rem 0;
}